<template>
    <div>
        <template v-if="progress < 100 && !embed">
            <template v-if="progress < 70">Calculating your cost...</template>
            <template v-else-if="progress < 100">Matching you with movers...</template>
            <v-progress-linear
                    v-model="progress"
                    color="info"
                    height="25"
            >
                <template v-slot:default="{ value }">
                    <strong>{{ Math.ceil(value) }}%</strong>
                </template>
            </v-progress-linear>
        </template>
        <div v-if="progress===100 && step < 5 && !embed" class="d-flex align-center justify-center pa-4 mx-auto">We found movers for you!</div>
        <v-card class="mb-12 mx-auto pa-10"
                :disabled="loading"
                :loading="loading"
                min-height="200px"
                v-show="progress===100 || embed">
            <v-card-title v-if="step === 1">
                Enter your e-mail
            </v-card-title>
            <v-card-title v-if="step === 2">
                Enter your name
            </v-card-title>
            <v-card-title v-if="step === 3">
                Enter your phone number
            </v-card-title>
            <v-card-title v-if="step === 4">
                Enter code from sms
            </v-card-title>

            <template v-if="step === 1">
                <v-text-field @update:error="emailValidation" :rules="rules" label="Email"
                              v-model="email"></v-text-field>
                <v-btn :disabled="!validEmail" @click="emitAndNextStep({email})">Continue</v-btn>
            </template>
            <template v-if="step === 2">
                <v-text-field label="First Name" v-model="name.first"></v-text-field>
                <v-text-field label="Last Name" v-model="name.last"></v-text-field>
                <v-btn :disabled="!(name.first && name.last)" @click="emitAndNextStep({name})">Continue</v-btn>
            </template>
            <template v-if="step === 3">
                <vue-tel-input @validate="numberValidation" :all-countries="allCountries" invalidMsg="invalid number"
                               :autoDefaultCountry="false" :validCharactersOnly="true"></vue-tel-input>
                <v-card-text v-if="phone && !validNumber">Number is not valid</v-card-text>
                <v-btn class="mt-3" :disabled="!validNumber" @click="sendSms">Continue</v-btn>
            </template>
            <template v-if="step === 4">
                <v-text-field counter="6" label="Code from sms" v-model="code" placeholder="000000"
                              type="tel"></v-text-field>
                <v-btn :disabled="!code" @click="checkCode">Continue</v-btn>
            </template>
            <template v-if="step === 5">
                <v-card-title>
                    You’re All Set!
                </v-card-title>
                <v-card-subtitle>
                    Your request has been submitted! Our moving adviser will contact you by phone or email shortly.
                </v-card-subtitle>
                <v-card-text>
                    Be sure to answer when he call. We must gather some final details about the items you are moving, in order to provide good service for you.
                </v-card-text>
            </template>

        </v-card>
        <v-snackbar
                app
                top
                color="error"
                outlined
                v-model="error"
                timeout="2000">
            {{errorMessage}}
        </v-snackbar>

    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "overlay",
        props: ["embed"],
        data() {
            return {
                test: false,
                progress: 0,
                code: null,
                loading: false,
                zIndex: 0,
                validEmail: false,
                validNumber: false,
                step: 1,
                phone: null,
                overlay: true,
                interval: null,
                email: "",
                error: false,
                errorMessage: "",
                name: {
                    first: "",
                    last: ""
                },
                allCountries: [
                    {
                        "name": "United States‎)",
                        "iso2": "US",
                        "dialCode": "1"
                    }
                ],
                rules: [
                    value => !!value || 'Required.',
                    value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    },
                ],
            }
        },
        methods: {
            emitAndNextStep(val) {
                this.$emit('leadUpdate', val);
                this.step++;
            },
            emailValidation(val) {
                this.validEmail = !val;
            },
            numberValidation(val) {
                this.validNumber = val.valid;
                if (val.valid) {
                    this.phone = val.number;
                }
            },
            count() {
                const limit = 100;
                let value = Math.floor(Math.random() * 15) + 1;
                if (this.progress + value > limit) {
                    value = limit - this.progress;
                }
                this.progress += value;
                if (this.progress === 100) {
                    clearInterval(this.interval);
                }
            },
            sendSms() {
                if (this.test || this.embed) {
                    this.step = 4;
                    let phone = this.phone;
                    this.emitAndNextStep({phone});
                    this.$emit('complete');
                } else {
                    this.loading = true;
                    axios.get(`https://smsvertify.vertoxone.workers.dev/send/${this.phone}`).then(resp => {
                        if (resp.data.status === "pending") {
                            this.step = 4;
                        } else {
                            this.errorMessage = resp.message;
                            this.error = true;
                        }
                        this.loading = false;
                    })
                }
            },
            checkCode() {
                if (this.test || this.embed) {
                    let phone = this.phone;
                    this.emitAndNextStep({phone});
                    this.$emit('complete');
                } else {
                    this.loading = true;
                    axios.get(`https://smsvertify.vertoxone.workers.dev/check/${this.phone}/${this.code}`).then(resp => {
                        if (resp.data.status === "approved") {
                            let phone = this.phone;
                            this.emitAndNextStep({phone});
                            this.$emit('complete');
                        } else {
                            this.errorMessage = "Invalid Code";
                            this.error = true;
                            this.loading = false;
                        }
                    })
                }
            }
        },
        mounted() {
            this.interval = setInterval(this.count, 500);
        },
        beforeDestroy() {
            clearInterval(this.interval);
        }
    }
</script>

<style scoped>

</style>