<template>
    <v-card
            color="info lighten-2"
            dark
    >
        <v-card-title class="text-h5 info lighten-3">
            {{header}}
        </v-card-title>
        <v-card-text>
            <v-autocomplete
                    @input="throwInput"
                    v-model="model"
                    :items="entries"
                    :loading="isLoading"
                    :search-input.sync="search"
                    color="white"
                    :item-text="text"
                    item-value="city"
                    label="Zip or City"
                    placeholder="Start typing to Search"
                    prepend-icon="mdi-database-search"
                    no-data-text="Start typing to Search"
                    return-object
            ></v-autocomplete>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "ZipSearch",
        data: () => ({
            descriptionLimit: 60,
            entries: [],
            isLoading: false,
            model: null,
            search: null,
        }),
        props: ["header"],
        computed: {
            fields() {
                if (!this.model) return []

                return Object.keys(this.model).map(key => {
                    return {
                        key,
                        value: this.model[key] || 'n/a',
                    }
                })
            },
            items() {
                return this.entries.map(entry => {
                    const Description = entry.Description.length > this.descriptionLimit
                        ? entry.Description.slice(0, this.descriptionLimit) + '...'
                        : entry.Description

                    return Object.assign({}, entry, {Description})
                })
            },
        },

        methods: {
            text: item => item.zip + ', ' + item.city,
            throwInput(val) {
                let result = {
                    zip: val.zip,
                    city: {
                        name: val.city.split(",")[0].trim(),
                        state: val.city.split(",")[1].trim(),
                    }
                }
                this.$emit('change', result);
            }
        },

        watch: {
            search(val) {
                // Items have already been loaded
                //if (this.items.length > 0) return

                // Items have already been requested
                if (this.isLoading) return
                if (val == null || val === " ") return
                this.isLoading = true

                // Lazily load input items
                fetch('https://zip.vertox.pro/api/v1/zip_find/' + val)
                    .then(res => res.json())
                    .then(res => {
                        const {total, items} = res
                        this.count = total
                        this.entries = items
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    .finally(() => (this.isLoading = false))
            },
        },
    }
</script>

<style scoped>
    .v-card__title {
        word-break: break-word;
    }
</style>