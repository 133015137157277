<template>
    <v-app>
        <v-app-bar
                app
        >
            <v-spacer/>
            <div>
                <v-app-bar-title>
                    <v-img
                            contain
                            src="./assets/Logo.svg"
                            :max-height="imageHeight"
                            max-width="320px"></v-img>
                </v-app-bar-title>
            </div>
            <v-spacer/>
        </v-app-bar>
        <v-main>
            <v-container>
                <calculator/>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    import Calculator from "./components/calculator";

    export default {
        name: 'App',

        components: {
            Calculator,
        },

        computed: {
            imageHeight () {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return '56px'
                    case 'sm': return '56px'
                    case 'md': return '64px'
                    case 'lg': return '64px'
                    case 'xl': return '64px'
                }
                return '64px'
            },
        },

        data: () => ({
            //
        }),
    };
</script>

<style scoped lang="scss">
    ::v-deep .v-application--wrap {
        min-height: fit-content;
    }
</style>
