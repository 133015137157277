<template>
    <v-stepper v-model="e1">
<!--        <v-stepper-header>-->
<!--            <v-stepper-step-->
<!--                    :complete="e1 > 1"-->
<!--                    step="1"-->
<!--            >-->
<!--                {{lead.from?text(lead.from):"Select from"}}-->
<!--            </v-stepper-step>-->

<!--            <v-divider></v-divider>-->

<!--            <v-stepper-step-->
<!--                    :complete="e1 > 2"-->
<!--                    step="2"-->
<!--            >-->
<!--                {{lead.to?text(lead.to):"Select to"}}-->
<!--            </v-stepper-step>-->

<!--            <v-divider></v-divider>-->

<!--            <v-stepper-step-->
<!--                    :complete="e1 > 3"-->
<!--                    step="3">-->
<!--                {{lead.size?filteredSize[lead.size].name:"Moving size"}}-->
<!--            </v-stepper-step>-->

<!--            <v-divider></v-divider>-->

<!--            <v-stepper-step-->
<!--                    :complete="e1 > 4"-->
<!--                    step="4">-->
<!--                {{lead.date?lead.date:"Select date"}}-->
<!--            </v-stepper-step>-->

<!--            <v-divider></v-divider>-->

<!--            <v-stepper-step-->
<!--                    :complete="e1 > 5"-->
<!--                    step="5">-->
<!--                5 step-->
<!--            </v-stepper-step>-->
<!--        </v-stepper-header>-->

        <v-stepper-items>
            <v-stepper-content step="1">
                <v-card
                        class="mb-12"
                        color="grey lighten-1"
                        min-height="100px"
                >
                    <zip-search @change="setFrom" header="Where are you moving from?"></zip-search>
                </v-card>

                <v-btn
                        color="primary"
                        @click="e1 = 2"
                        :disabled="!lead.from"
                >
                    Continue
                </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
                <v-card
                        class="mb-12"
                        color="grey lighten-1"
                        min-height="100px"
                >
                    <zip-search @change="setTo" header="Where are you moving to?"></zip-search>
                </v-card>

                <v-btn
                        color="primary"
                        @click="e1 = 3"
                        :disabled="!lead.to"
                >
                    Continue
                </v-btn>

                <v-btn text @click="e1 = 1">
                    Back
                </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
                <v-card
                        class="mb-12"
                        color="info lighten-3"
                        min-height="200px"
                >
                    <v-card-title v-if="lead.sizeType === null">Property type</v-card-title>
                    <v-card-title v-else>Move size</v-card-title>
                    <template v-if="lead.sizeType === null">
                        <v-item-group active-class="primary" v-model="lead.sizeType">
                            <v-row>
                                <v-col v-for="item in sizeTypes" :key="item" cols="6" class="pa-0">
                                    <v-item v-slot="{ active, toggle }">
                                        <v-card
                                                :color="active ? 'primary' : 'grey'"
                                                class="d-flex align-center"
                                                dark
                                                min-height="100"
                                                @click="toggle">
                                            <v-scroll-y-transition>
                                                <div class="flex-grow-1 text-center">
                                                    {{item}}
                                                </div>
                                            </v-scroll-y-transition>
                                        </v-card>
                                    </v-item>
                                </v-col>
                            </v-row>
                        </v-item-group>
                    </template>
                    <template v-else>
                        <v-item-group active-class="primary" v-model="lead.size">
                            <v-row>
                                <!--                            <v-col cols="4" v-for="item in sizeOption" :key="item">-->
                                <!--                                <v-btn>-->
                                <!--                                    {{item}}-->
                                <!--                                </v-btn>-->
                                <!--                            </v-col>-->
                                <v-col
                                        v-for="item in filteredSize"
                                        :key="item.name"
                                        cols="6"
                                        sm="4"
                                        class="pa-0"
                                >
                                    <v-item v-slot="{ active, toggle }">
                                        <v-card
                                                :color="active ? 'primary' : 'grey'"
                                                class="d-flex align-center"
                                                dark
                                                min-height="100"
                                                @click="toggle">
                                            <v-scroll-y-transition>
                                                <div class="flex-grow-1 text-center">
                                                    {{item.name}}
                                                </div>
                                            </v-scroll-y-transition>
                                        </v-card>
                                    </v-item>
                                </v-col>
                            </v-row>
                        </v-item-group>
                    </template>
                </v-card>

                <v-btn
                        color="primary"
                        @click="e1 = 4"
                >
                    Continue
                </v-btn>

                <v-btn text @click="flushSizeType">
                    Back
                </v-btn>
            </v-stepper-content>
            <v-stepper-content step="4">
                <v-card class="mb-12"
                        min-height="200px">
                    <v-card-title>Select your move date</v-card-title>
                    <v-row justify="center">
                        <v-date-picker :min="nowDate" v-model="lead.date"></v-date-picker>
                    </v-row>
                </v-card>
                <v-btn
                        color="primary"
                        @click="overlay = true"
                >
                    Continue
                </v-btn>

                <v-btn text @click="e1 = 3">
                    Back
                </v-btn>
            </v-stepper-content>
            <v-stepper-content step="5">
                <v-card class="mb-12"
                        min-height="200px">
                    <v-row justify="center">

                    </v-row>
                </v-card>
                <v-btn
                        color="primary"
                        @click="e1 = 1"
                >
                    Continue
                </v-btn>

                <v-btn text @click="e1 = 3">
                    Back
                </v-btn>
            </v-stepper-content>
        </v-stepper-items>
        <v-overlay :light="true"
                   :dark="false"
                   z-index="0"
                   :value="overlay"
        >
            <overlay :embed="embed" @leadUpdate="leadUpdate" @complete="sendLead"/>
        </v-overlay>
    </v-stepper>
</template>

<script>
    import ZipSearch from "./ZipSearch";
    import Overlay from "./overlay";

    import Axios from "axios";

    export default {
        name: "calculator",
        components: {Overlay, ZipSearch},
        mounted() {
            if (location.host !== "movinator.pages.dev"){
                this.embed = true;
            }
        },
        computed: {
            filteredSize() {
                return this.lead.sizeType !== null ? this.sizeOptionObject.filter(soo => soo.type === this.sizeTypes[this.lead.sizeType]) : {};
            }
        },
        data() {
            return {
                nowDate: new Date().toISOString().slice(0,10),
                lead: {
                    from: null,
                    to: null,
                    date: null,
                    sizeType: null,
                    size: null,
                    name: null,
                    email: null,
                    phone: null,
                    bedrooms: null,
                },
                embed: false,
                overlay: false,
                e1: 1,
                from: null,
                to: null,
                size: null,
                date: null,
                sizeType: null,
                sizeOption: [
                    "Studio apt",
                    "1 Bedroom apt",
                    "2 Bedrooms apt",
                    "3 Bedrooms apt",
                    "4+ Bedrooms apt",
                    "1 Bedroom house",
                    "2 Bedroom house",
                    "3 Bedroom house",
                    "4 Bedroom house"
                ],
                sizeTypes: [
                    "Apartment",
                    "House"
                ],
                sizeOptionObject: [
                    {
                        name: "Studio",
                        type: "Apartment",
                        bedrooms: 0
                    },
                    {
                        name: "1 Bedroom",
                        type: "Apartment",
                        bedrooms: 1
                    },
                    {
                        name: "2 Bedrooms",
                        type: "Apartment",
                        bedrooms: 2
                    },
                    {
                        name: "3+ Bedrooms",
                        type: "Apartment",
                        bedrooms: 3
                    },
                    {
                        name: "2 Bedroom house",
                        type: "House",
                        bedrooms: 2
                    },
                    {
                        name: "3 Bedroom house",
                        type: "House",
                        bedrooms: 3
                    },
                    {
                        name: "4+ Bedroom house",
                        type: "House",
                        bedrooms: 4
                    },
                ]
            }
        },
        methods: {
            flushSizeType(){
                this.e1 = 2;
                this.lead.sizeType = null;
            },
            sendLead() {
                let lead = {...this.lead};
                lead.sizeType = this.sizeTypes[lead.sizeType];
                lead.bedrooms = this.filteredSize[lead.size].bedrooms
                lead.size = this.filteredSize[lead.size].name;

                Axios.post("https://leadproxy.vertoxone.workers.dev/api/lead", JSON.stringify(lead)).then(() => {

                })
            },
            leadUpdate(val) {
                this.lead[Object.keys(val)[0]] = Object.values(val)[0];
            },
            setFrom(val) {
                this.lead.from = val;
            },
            setTo(val) {
                this.lead.to = val;
            },
            text: item => item.zip + ', ' + item.city,
        }
    }
</script>

<style scoped>

</style>

<style>
    .v-overlay.v-overlay--active{
        backdrop-filter: blur(10px);
    }
</style>